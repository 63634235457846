import React from "react";
import "./style.scss";
import BackgroundImage from "./BackgroundImage";
import Content from "./Content";
import { useRef } from "react";
import { useEffect } from "react";
// import ContextMenu from "./contextmenu";

export default function LandingPage() {
    // document.addEventListener("contextmenu", (event) => {
    //     event.preventDefault();
    //     const xPos = event.pageX + "px";
    //     const yPos = event.pageY + "px";
    //     console.log(xPos, yPos);
    // });
    let width = window.innerWidth;
    let landing = useRef(null);
    useEffect(() => {
        width < 768 && landing.current.classList.add("bgimg");
        // eslint-disable-next-line
    }, []);
    return (
        <div ref={landing} className="landingpage">
            {width > 768 && <BackgroundImage />}
            <Content />
            {/* <ContextMenu /> */}
        </div>
    );
}
