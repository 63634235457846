import React from "react";

export default function Name() {
  let colorList = [
    "#2302ec",
    "#3fff00",
    "#d8ff00",
    "#ff9900",
    "#f00",
    "#00fffc",
    "#a102ec",
  ];
  let i = 0;

  let myVar;
  const clearTime = () => {
    clearTimeout(myVar);
  };

  const settime = () => {
    myVar = setTimeout(function () {
      console.log("exec");
      let element = document.getElementById("Name").style;
      element.background = `linear-gradient(135deg, #00fffc, #2302ec`;
      element.webkitBackgroundClip = "text";
      element.webkitTextFillColor = "transparent";
    }, 2000);
  };
  const handleScroll = () => {
    myVar && clearTime();
    let element = document.getElementById("Name").style;
    element.transition = "all 2s ease-in-out";
    console.log(colorList[i], colorList[i + 1]);
    element.background = `linear-gradient(135deg, ${colorList[i]}, ${
      colorList[i - 1]
    })`;
    element.webkitBackgroundClip = "text";
    element.webkitTextFillColor = "transparent";
    element.transition = "all 2s ease-in-out";
    i < colorList.length - 2 ? (i += 1) : (i = 0);
    settime();
  };
  window.addEventListener("wheel", handleScroll);
  return (
    <div className="heading" id="Name">
      <span onClick={() => clearTimeout(myVar)}>Vignesh Subramanian</span>
      {/* <span>V</span>
      <span>ignesh </span>
      <span> S</span>
      <span>ubramanian</span> */}
    </div>
  );
}
