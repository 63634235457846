import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import logo from "../../Asserts/Image/logo.png";

export default function BackgroundImage() {
    // const forceUpdate = useForceUpdate();
    let list = [];
    for (let i = 0; i < 5000; i++) {
        list.push(i);
    }
    const [heightImg, setheightImg] = useState([]);
    const [widthImg, stewidthImg] = useState([]);
    const calculateImageCount = () => {
        let width = window.innerWidth;
        let height = window.innerHeight;
        let widthcount = Math.floor(width / 32);
        let heightcount = Math.ceil(height / 18) + 2;
        let heightCalc = [...Array(heightcount).keys()];
        let widthCalc = [...Array(widthcount).keys()];
        setheightImg(heightCalc);
        stewidthImg(widthCalc);
        console.log(heightImg, widthImg);
        // forceUpdate();
    };
    useEffect(() => {
        console.log("called");
        calculateImageCount();
        window.addEventListener("resize", calculateImageCount);
        // eslint-disable-next-line
    }, []);
    return (
        <div className="image-bg">
            {heightImg.map((h) => {
                return (
                    <React.Fragment>
                        {h % 2 ? (
                            <React.Fragment>
                                <span style={{ width: "17px" }}></span>
                                {widthImg
                                    .slice(0, widthImg.length - 1)
                                    .map((w) => {
                                        return (
                                            <img
                                                key={h + w}
                                                className="background-image"
                                                src={logo}
                                                alt=""
                                                width="32"
                                                height="18"
                                                srcSet=" "
                                            />
                                        );
                                    })}
                                <span style={{ width: "16px" }}></span>
                            </React.Fragment>
                        ) : (
                            widthImg.map((w) => {
                                return (
                                    <img
                                        key={h + w}
                                        className="background-image"
                                        src={logo}
                                        alt=""
                                        width="32"
                                        height="18"
                                        srcSet=" "
                                    />
                                );
                            })
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
}
