import React from "react";
import "./styles/skill.scss";
import aws from "../../Asserts/Image/Skil/AWS.png";
import docker from "../../Asserts/Image/Skil/Docker.png";
import Jenkins from "../../Asserts/Image/Skil/Jenkins.png";
import node from "../../Asserts/Image/Skil/Node.png";
import react from "../../Asserts/Image/Skil/React.png";
import rest from "../../Asserts/Image/Skil/RestAPI.png";
import graphQL from "../../Asserts/Image/Skil/GraphQL.png";
import { useEffect } from "react";
import SkillOverlay from "./SkillOverlay";
import { useState } from "react";

export default function Skill() {
    let animation;
    let i = 1;
    const [index, setIndex] = useState(i);
    const [info, setInfo] = useState(false);
    const idlist = [
        "aws",
        "docker",
        "jenkins",
        "node",
        "react",
        "rest",
        "graphQL",
    ];
    const animationFunc = () => {
        setInfo(false);
        window.navigator.vibrate([10, 10, 10, 10, 10, 10, 10, 10, 10]);
        animation = setInterval(() => {
            document.querySelectorAll(".skill-image").forEach((el) => {
                el.classList.add("hidden");
            });
            document.querySelector(`#${idlist[i]}`).classList.remove("hidden");
            document.querySelector(`#${idlist[i]}`).classList.add("view");
            i < 6 ? i++ : (i = 0);
            setIndex(i);
        }, 500);
    };
    const imageClick = (e) => {
        navigator.vibrate(10);
        e.preventDefault();
        e.stopPropagation();
        clearInterval(animation);
        setInfo(true);
        return false;
    };

    useEffect(() => {
        animationFunc();
        window.addEventListener("mousedown", imageClick);
        window.addEventListener("mouseup", animationFunc);
        window.addEventListener("touchstart", imageClick);
        window.addEventListener("touchend", animationFunc);
        // eslint-disable-next-line
    }, []);

    let counter = 0;
    const updateRate = 10;
    const limit = 45;
    const tiltable = document.getElementById("tiltable");

    function updateNow() {
        return counter++ % updateRate === 0;
    }

    window.addEventListener("deviceorientation", function (event) {
        console.log(event);
        if (updateNow()) {
            let position = Math.round(event.gamma);
            if (Math.abs(position) > limit) {
                if (position > limit) {
                    position = limit;
                } else {
                    position = -limit;
                }
            }
            position = position / -100;
            let style = "rotateY(" + position + "deg)";
            tiltable.style.transform = style;
        }
    });

    return (
        <div className="skill">
            <div id="tiltable" className="image-skill-group">
                <img
                    // onTouchStart={imageClick}
                    // onTouchEnd={animationFunc}
                    className="skill-image"
                    alt="aws-logo"
                    id="aws"
                    src={aws}
                />
                <img
                    // onTouchStart={imageClick}
                    // onTouchEnd={animationFunc}
                    className="Overly hidden skill-image"
                    alt="docker-logo"
                    src={docker}
                    id="docker"
                />
                <img
                    // onTouchStart={imageClick}
                    // onTouchEnd={animationFunc}
                    className="Overly hidden skill-image"
                    alt="Jenkins-logo"
                    src={Jenkins}
                    id="jenkins"
                />
                <img
                    // onTouchStart={imageClick}
                    // onTouchEnd={animationFunc}
                    className="Overly hidden skill-image"
                    alt="node-logo"
                    src={node}
                    id="node"
                />
                <img
                    // onTouchStart={imageClick}
                    // onTouchEnd={animationFunc}
                    className="Overly hidden skill-image"
                    alt="react-logo"
                    src={react}
                    id="react"
                />
                <img
                    // onTouchStart={imageClick}
                    // onTouchEnd={animationFunc}
                    className="Overly hidden skill-image"
                    alt="rest-logo"
                    src={rest}
                    id="rest"
                />
                <img
                    // onTouchStart={imageClick}
                    // onTouchEnd={animationFunc}
                    className="Overly hidden skill-image"
                    alt="graphQL-logo"
                    src={graphQL}
                    id="graphQL"
                />
            </div>
            {info && <SkillOverlay i={index} />}
        </div>
    );
}
