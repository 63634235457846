import React from "react";
// import logoBlack from "../../Asserts/Image/logo-black.png";
import heroImage from "../../Asserts/Image/vignesh.png";
import Name from "./Name";
import Skill from "./Skill";

export default function Content() {
    return (
        <div className="content">
            <div className="image">
                <img src={heroImage} alt="" srcSet="" />
            </div>
            <div className="email">
                <a href="mailto:vigneshsubramanian15@gmail.com">
                    {" "}
                    vigneshsubramanian<span>15</span>@gmail.com{" "}
                </a>
            </div>
            <Name />
            <div className="designation">
                <span>Fullstack Developer</span>
            </div>
            <div className="description">
                <p>
                    &nbsp; I'm a self-motivated, self-taught professional who is
                    attracted by challenges. I instinctively merge usability and
                    user experience with technical knowledge to create cool
                    digital experience.
                </p>
            </div>
            <div className="link">
                <span>About Me</span>
                <span>About You</span>
            </div>
            <Skill />
        </div>
    );
}
