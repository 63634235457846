import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import aws from "../../Asserts/Image/Skil/AWS.png";
import docker from "../../Asserts/Image/Skil/Docker.png";
import Jenkins from "../../Asserts/Image/Skil/Jenkins.png";
import node from "../../Asserts/Image/Skil/Node.png";
import react from "../../Asserts/Image/Skil/React.png";
import rest from "../../Asserts/Image/Skil/RestAPI.png";
import graphQL from "../../Asserts/Image/Skil/GraphQL.png";
import { useState } from "react";
export default function SkillOverlay({ i }) {
    let overlay = useRef(null);
    const [index, setIndex] = useState(0);
    useEffect(() => {
        overlay.current.classList.add("display");
    }, []);
    useEffect(() => {
        i === 0 ? setIndex(6) : setIndex(i - 1);
    }, [i]);
    const heading = [
        "aws",
        "docker",
        "jenkins",
        "node",
        "react",
        "rest",
        "graphql",
    ];
    const color = [
        "#f9991b",
        "#2296ee",
        "#f53431",
        "#3e863d",
        "#50c2e0",
        "#0273a2",
        "#e10198",
    ];
    const content = [
        "I have exposure to various services in AWS to meet different requirements for different projects in my career. The Services that I have used are EC2, Lambda, Amplify, S3, LightSail, Route53.",
        "",
        "I have used Jenkins to build Continuous Integration and Continuous delivery pipeline for the product VIZB at DCKAP which gave me exposure in building a auto deployment pipeline and also various methods of creating a CI/CD pipeline.",
        "As Node JS can provide the best in class performance in API handling with both REST API and GraphQL I can build a full fledged API service.  On a top level i have experience in building features like Login Management, User Subscription, Email Services, User Role Management, Integration Credential Handling",
        "Leveraging the Virtual dom technology and with the latest React Hooks concept I can build an extensive SAAS based Application with is highly efficient and with low Latency by adding ReduxJS with React Hooks I can process large data efficiently in the client side",
        "In the world of micro service application and headless commerce design application technologies like RestAPI play a major role in transferring data from sources to multiple destinations.  I have a handful of experience in building  Rest API and a GraphQL api with a  deep understanding on how these technologies functions ",
        "In the world of micro service application and headless commerce design application technologies like RestAPI play a major role in transferring data from sources to multiple destinations.  I have a handful of experience in building  Rest API and a GraphQL api with a  deep understanding on how these technologies functions ",
    ];
    return (
        <div ref={overlay} className="Skill-overlay">
            <div className="content">
                <h2>{heading[index]}</h2>
                <p style={{ color: `${color[index]}` }}>
                    &emsp;&emsp; {content[index].toLowerCase()}
                </p>
            </div>
            <div className="skill">
                <div className="image-skill-group null-filter">
                    <img
                        className="skill-image"
                        alt="aws-logo"
                        id="aws"
                        src={
                            i === 1
                                ? aws
                                : i === 2
                                ? docker
                                : i === 3
                                ? Jenkins
                                : i === 4
                                ? node
                                : i === 5
                                ? react
                                : i === 6
                                ? rest
                                : graphQL
                        }
                        // width="450"
                        // height="450"
                    />
                </div>
            </div>
        </div>
    );
}
